import $ from 'jquery';
import { firstByClass } from 'OK/utils/dom';
import throttle from 'OK/utils/throttle';
import { SliderTimer, SliderTimerState } from 'OK/SliderTimer';
import { SliderMarkers } from 'OK/SliderMarkers';

var instances = [];

var mouseWheelSupport = document.documentElement.classList.contains('js-uslider-wheel-support');

var EVENT_WHEEL = 'wheel';

function setTransform($e, transform) {
    $e[0].style.cssText = "transform: " + transform
    + "; -webkit-transform: " + transform
    + "; -moz-transform: " + transform
    + "; -ms-transform: " + transform
    + ";";
}

/**
 * Реализует управление кнопками вперед / назад
 */
class USliderControl {
    /**
     * @param {jQuery} $el - сама кнопка
     * @param {Number} lockDelay - если не 0, то предотвращает случайный клик по карде под кнопкой после окончания скролла
     */
    constructor($el, lockDelay) {
        this.el = $el[0];
        this.lockDelay = lockDelay;
        this._onLockTimeout = this._onLockTimeout.bind(this);
    }

    hide() {
        if (this.locked) {
            return;
        }

        if (!this.lockDelay) {
            this.setHidden(true);
        } else {
            this.lock();
        }
    }

    setHidden(hidden) {
        if (this.el) {
            this.el.classList.toggle('hidden', hidden);
        }
    }

    setLocked(locked) {
        this.locked = locked;
        if (this.el) {
            this.el.classList.toggle('__lock', locked);
        }
    }

    lock() {
        this.setLocked(true);
        this.lockTimeout = setTimeout(this._onLockTimeout, this.lockDelay);
    }

    unlock() {
        if (!this.locked) {
            return;
        }
        this.setLocked(false);
        if (this.lockTimeout) {
            clearTimeout(this.lockTimeout);
            this.lockTimeout = 0;
        }
    }

    _onLockTimeout() {
        this.lockTimeout = 0;
        this.unlock();
        this.setHidden(true);
    }

    show() {
        if (this.locked) {
            this.unlock();
        }
        this.setHidden(false);
    }

    onClick(callback) {
        if (this.el) {
            this.callback = callback;
            this.el.addEventListener('click', this);
        }
    }

    handleEvent(e) {
        if (e.type === 'click' && !this.locked && this.callback) {
            this.callback();
        }
    }

    destroy() {
        if (this.el) {
            if (this.callback) {
                this.el.removeEventListener('click', this);
                this.callback = null;
            }
            this.el = null;
        }
    }
}


class USlider {
    /**
     * @param {HTMLElement} element
     */
    activate(element) {
        var me = this, $el = me.$el = $(element);

        me.$content = $el.find('.uslider_cnt');

        var dataType = $el.attr("data-type");
        me.isCarousel = dataType !== undefined && dataType === "carousel";
        var dataPlay = $el.attr("data-play");
        me.autoplay = dataPlay !== undefined && dataPlay === "auto";
        var customEvent = $el.attr("data-customEvent");
        me.customEvent = customEvent !== undefined && customEvent === "true";
        var deleteEnabled = $el.attr('data-delete-enabled');

        me.intervalTime = +$el.attr("data-interval");

        var lockDelayOfScrollingEnd = +$el.attr("data-lock-delay-of-scrolling-end") || 0;

        var inLayer = $el.attr("data-in-layer");
        me.inLayer = inLayer !== undefined && inLayer === "true";

        var loop = $el.attr("data-loop");
        me.loop = loop !== undefined && loop === "true";
        me.autoPlayForward = true;

        var manualGap = $el.attr('data-gap');
        me.options = {
            gap: manualGap ? parseInt(manualGap) : 6
        };

        if (me.isCarousel) {
            me.options.gap = 0;
        }

        var $nextBtn = me.$nextBtn = new USliderControl($el.find('.uslider_ctrl.__next'), lockDelayOfScrollingEnd);
        var $prevBtn = me.$prevBtn = new USliderControl($el.find('.uslider_ctrl.__prev'), lockDelayOfScrollingEnd);

        var $container = me.$container = $el.find('.uslider_hld');

        me.manualSlideWidth = $el.attr('data-slide-width');
        var children = me.$children = $container.children(); // имеющиеся слайды (.uslider_i).

        me.updateSlideWidth();

        var manualVisibleSlides = $el.attr('data-visibleSlides');
        // сколько слайдов одновременно видимо
        me.maxVisibleSlides = me.calcVisibleSlides();
        me.visibleSlides = manualVisibleSlides
            ? parseInt(manualVisibleSlides)
            : me.maxVisibleSlides;
        $el.attr('data-visibleSlides', me.visibleSlides);

        // уведомляет портлеты о том, что data-visibleSlides установлен
        me.createLoadDataVisibleAttributeEvent();

        var ring = $el.attr("data-ring");
        me.ring = ring !== undefined && ring === "true" && children.length >= me.visibleSlides * 4;

        var ringManualInitTransform = $el.attr("data-ring-manual-init-transform");
        me.ringManualInitTransform = ringManualInitTransform !== undefined && ringManualInitTransform === "true";

        var resizable = $el.attr("data-resizable");
        me.resizable = resizable !== undefined && resizable === "true";

        //ширина "ушей" слева и справа от целиком вместившихся слайдов (всегда меньше ширины одного слайда)
        var manualButtonOffset = $el.attr('data-manualButtonOffset');
        me.buttonOffset = manualButtonOffset
            ? parseInt(manualButtonOffset)
            : Math.floor((me.$content.outerWidth() - (me.visibleSlides * me.slideWidth)) / 2);

        me.totalSlides = children.length; //сколько всего слайдов

        var markersEl = firstByClass(element, 'js-markers');
        if (markersEl != null) {
            this.markers = new SliderMarkers(
                markersEl,
                this.onClickMarker.bind(this)
            );
        }

        me.offset = 0; //номер самого левого видимого слайда
        me.offsetRingDelta = 0;

        //изначально кнопки листания скрыты, нужно привести их в нужное настроение:
        if (me.ring) {
            $nextBtn.show();
            $prevBtn.show();
            me.offset = me.visibleSlides;
            me.updateMarkers();
            if (!me.ringManualInitTransform) {
                $container.addClass("__noanim");
                var countdown = Math.min(Math.floor((me.totalSlides - me.visibleSlides) / 3), me.visibleSlides);
                var elem = $container[0];
                while (countdown-- > 0) {
                    elem.insertBefore(elem.lastChild, elem.firstChild);
                }
                me.setTransalateX(me.offset * me.slideWidth - me.buttonOffset);
                setTimeout(function () {
                    $container.removeClass("__noanim");
                }, 50);
            }
        } else {
            me.scrollToOffset();
        }

        $nextBtn
            .onClick(function () {
                me.handleClick(true); //обработка клика
                me.resetAutoPlayInterval();
            });

        $prevBtn
            .onClick(function () {
                me.handleClick(false); //обработка клика
                me.resetAutoPlayInterval();
            });

        $el.on("uSliderUpdateCount", function (e) {
            var detail = e.originalEvent.detail;

            switch (detail.type) {
                case 'remove':
                    me.deleteItem(true);
                    break;
                case 'append':
                    me.updateCount();

                    if (detail.offset !== undefined) {
                        me.setOffset(detail.offset);
                    } else {
                        me.scrollToOffset();
                    }
                    break;
            }
        });

        $el.on("uSliderClick", function (e) {
            me.handleClick(e.originalEvent.detail.forward);
            me.resetAutoPlayInterval();
        });

        if (mouseWheelSupport) {
            element.addEventListener(EVENT_WHEEL, this);
        }

        $container.on("transitionend.slider", function (e) {
            // Внутри слайдера может всплывать чужая анимация, она нам не нужна
            if (e.target === $container[0]) {
                me.createCustomSlideEvent("slideend");
            }
        });

        if (me.autoplay && me.totalSlides > 1) {
            me.timer = new SliderTimer(me.onTimerDone, me.onTimerProgress, me.onTimerStateChanged, me.intervalTime, me);

            $el.hover(function () {
                me.timer.pause();
                me.markers.setProgress(0);
            }, function () {
                me.timer.start();
            });

            me.timer.start();
        }

        if (deleteEnabled) {
            $container.on('click.slide', '.js-delete-item', function () {
                me.deleteItem(false);
            });
        }

        if (me.resizable) {
            me.isOldLayoutIsSmall = $(window).width() < 1274;
            $(window).on('resize.slider', throttle(200, function () {
                me.resizeHandler();
            }));
        }

        var index = instances.indexOf(element);
        if (index === -1) {
            instances.push(element);
        }
    }

    calcVisibleSlides() {
        return Math.floor((this.$content.outerWidth() + this.options.gap * 2) / this.slideWidth);
    }

    updateCount() {
        this.totalSlides = this.$container.children().length;
    }

    setOffset(offset) {
        var _offset, maxOffset = this.totalSlides - this.visibleSlides;
        switch (offset) {
            case 'start':
                _offset = 0;
                break;
            case 'end':
                _offset = maxOffset;
                break;
            default:
                _offset = offset;
                if (_offset > maxOffset) {
                    _offset = maxOffset;
                }
                if (_offset < 0) {
                    _offset = 0;
                }
                break;
        }
        this.offset = _offset;
        this.scrollToOffset();
    }

    deactivate() {
        var $el = this.$el;
        $el.find(".uslider_ctrl").off(".slider");
        $el.off("uSliderUpdateCount");
        $el.off("uSliderClick");

        var element = $el[0];

        if (mouseWheelSupport) {
            element.removeEventListener(EVENT_WHEEL, this);
        }

        if (this.timer) {
            this.timer.abort();
        }

        if (this.markers) {
            this.markers.destroy();
        }

        if (this.$nextBtn) {
            this.$nextBtn.destroy();
            this.$nextBtn = null;
        }

        if (this.$prevBtn) {
            this.$prevBtn.destroy();
            this.$prevBtn = null;
        }

        var index = instances.indexOf(element);
        if (index !== -1) {
            instances.splice(index, 1);
        }

        if (!instances.length) {
            if (this.resizable) {
                $(window).off('resize.slider');
            }
        }
    }

    /**
     * @param {Event} e
     */
    handleEvent(e) {
        switch (e.type) {
            case EVENT_WHEEL:
                this.onMouseWheel(e);
                break;
        }
    }

    setTransalateX(x) {
        setTransform(this.$container, 'translateX(-' + x + 'px)');
    }

    createCustomSlideEvent(action, data) {
        var me = this;
        if (me.customEvent && typeof CustomEvent === "function") {
            me.$el[0].dispatchEvent(
                new CustomEvent("uSliderAction", {
                    bubbles: true,
                    detail: {
                        offset: me.offset,
                        totalSlides: me.totalSlides,
                        visibleSlides: me.visibleSlides,
                        container: me.$container[0],
                        action: action,
                        data: data || null
                    }
                })
            );
        }
    }

    createLoadDataVisibleAttributeEvent() {
        var me = this;
        if (me.customEvent && typeof CustomEvent === "function") {
            me.$el[0].dispatchEvent(
                new CustomEvent("dataVisibleSlidesLoadOrChanged")
            );
        }
    }

    handleClick(forward) {
        // this.offset - определяем номер самого левого слайда (даже частино видимого) после вращения:
        var me = this;

        if (me.isCarousel && me.offset == me.totalSlides - 1 && forward) {
            me.offset = 0;
        } else if (forward) {
            // если листаем в право
            me.offset += (me.offset + me.visibleSlides) < (me.totalSlides - me.visibleSlides)
                ? me.visibleSlides
                : me.totalSlides - (me.offset + me.visibleSlides);
        } else {
            // если листаем в лево
            me.offset -= (me.offset - me.visibleSlides > 0)
                ? me.visibleSlides
                : me.offset;
        }

        // выполняем скролл к выбранному слайду (data.offset)
        me.scrollToOffset();

        me.createCustomSlideEvent("slide");
    }

    /**
     * Cкручиваем слайдер к слайду с номером data.offset.
     * Слайды центруются так, чтобы самый левый и самый правый одинаково выступали за пределы видимой области
     * (если обе стрелки видимы).
     *
     * Центровать можно двумя способами:
     *
     * off                       off+s
     * -|-----|-----|-----|-----|-   visibleSlides = 4
     * ----|-----|-----|-----|----   visibleSlides = 3
     * off                    off+s
     *
     * Выбираем вариант, где больше слайдов вмещается целиком.
     */
    scrollToOffset() {

        var me = this, $container = me.$container, $nextBtn = me.$nextBtn, $prevBtn = me.$prevBtn;

        if (me.ring && (me.offset < 1 || me.offset >= me.totalSlides - me.visibleSlides)) {
            $container.addClass("__noanim");
            var elem = $container[0],
                sign = Math.min(Math.floor((me.totalSlides - me.visibleSlides) / 3), me.visibleSlides),
                countdown = sign;
            if (me.offset < 1) {
                while (countdown-- > 0) {
                    me.createCustomSlideEvent('moveFromRightToLeft', elem.lastChild);
                    elem.insertBefore(elem.lastChild, elem.firstChild);
                }
            } else {
                sign = -sign;
                while (countdown-- > 0) {
                    me.createCustomSlideEvent('moveFromLeftToRight', elem.firstChild);
                    elem.appendChild(elem.removeChild(elem.firstChild));
                }
            }
            me.offset += sign;
            me.offsetRingDelta += sign;

            me.updateMarkers();

            me.setTransalateX((me.offset + sign) * me.slideWidth - me.buttonOffset);

            setTimeout(function () {
                $container.removeClass("__noanim");
                me.setTransalateX(me.offset * me.slideWidth - me.buttonOffset);
            }, 50);
            return;
        }

        if (me.offset < 1) {
            // откручиваем влево до предела (нет левой кнопки)
            $prevBtn.hide();
            if (me.visibleSlides < me.totalSlides) {
                // Можно настроить, центровку слайдов так, что реальное
                // количество слайдов, влезающих на экран, больше visibleSlides.
                // Обрабатываем этот случай
                if (me.maxVisibleSlides > me.visibleSlides && me.maxVisibleSlides >= me.totalSlides) {
                    $nextBtn.hide(); // контент влезает, правая кнопка всё-таки невидима
                } else {
                    //делаем правую кнопку видимой, только если она действительно нужна.
                    $nextBtn.show();
                }
            } else {
                $nextBtn.hide();
            }
            me.setTransalateX(me.offset * me.slideWidth + me.options.gap);
        } else if (me.offset < me.totalSlides - me.visibleSlides) {
            // откручиваем куда-то в середину (есть обе кнопки)
            $prevBtn.show();
            $nextBtn.show();

            me.setTransalateX(me.offset * me.slideWidth - me.buttonOffset);

        } else {
            // откручиваем вправо до предела (нет правой кнопки)
            $prevBtn.show();
            $nextBtn.hide();
            me.setTransalateX(me.offset * me.slideWidth - me.buttonOffset * 2 - me.options.gap);
        }

        me.updateMarkers();
    }

    updateMarkers() {
        if (!this.markers) {
            return;
        }
        var offset;
        if (this.ring) {
            offset = (this.offset - this.offsetRingDelta) % this.totalSlides; // с учётом, что это может быть ring
            offset -= 1; // В ring всегда отсчёт от второго слайда. Делаем вид, что это первый
            if (offset < 0) {
                offset += this.totalSlides;
            }
        } else {
            offset = this.offset;
        }

        this.markers.setIndex(offset);
    }

    onClickMarker(index) {
        if (this.ring) {
            var currentOffset = (this.offset - this.offsetRingDelta) % this.totalSlides; // с учётом, что это может быть ring

            // В ring всегда отсчёт от второго слайда. Корректируем на 1 (см. updateMarkers)
            this.offset += index - currentOffset + 1;
            if (this.offset >= this.totalSlides) {
                this.offset -= this.totalSlides;
            }
            if (this.offset < 0) {
                this.offset += this.totalSlides;
            }
        } else {
            this.offset = index;
        }
        this.scrollToOffset();
        this.resetAutoPlayInterval();
    }

    onTimerDone() {
        var me = this;
        var sliderTop = me.$content.offset().top;
        if ((OK.Layers.isAnyLayerOpened() && !me.inLayer) || $(window).scrollTop() > sliderTop) return;
        if (me.loop) {
            var offset = me.offset;
            me.handleClick(me.autoPlayForward);
            if (offset === me.offset) {
                me.handleClick(me.autoPlayForward = !me.autoPlayForward);
            }
        } else {
            me.handleClick(true);
        }
        me.resetAutoPlayInterval();
    }

    onTimerStateChanged(state) {
        if (this.markers) {
            this.markers.setPaused(state === SliderTimerState.Paused);
        }
    }

    onTimerProgress(progress) {
        if (this.markers) {
            this.markers.setProgress(progress);
        }
    }

    resetAutoPlayInterval() {
        if (this.timer && (this.timer.getState() !== SliderTimerState.Paused)) {
            this.timer.start();
        }
    }

    // Нужна общая функция для обновления
    deleteItem(sync) {
        var me = this;
        // уменьшаем количество слайдов сразу, а не ждём пока он удалится посл ajax запроса
        me.totalSlides = sync
            ? me.$container.children().length
            : me.$container.children().length - 1;
        var prevOffset = me.offset;
        if (me.offset + me.visibleSlides > me.totalSlides) {
            me.offset = me.offset > 0 ? --prevOffset : prevOffset;
        }
        me.scrollToOffset();

        // Оповестим, что что-то произошло внутри
        me.createCustomSlideEvent("remove");
    }

    // пересчитываем ширину слайда
    updateSlideWidth() {
        var me = this;
        me.slideWidth = me.manualSlideWidth ? me.manualSlideWidth : $(me.$children[0]).outerWidth(true);
    }

    // пересчитываем основные параметры и сбрасывает прокрутку
    resizeHandler() {
        var me = this;

        // если не было смены лейаута, то и пересчитывать не будем
        var isNewLayoutIsSmall = $(window).width() < 1274;
        if (me.isOldLayoutIsSmall === isNewLayoutIsSmall) {
            return false;
        } else {
            me.isOldLayoutIsSmall = isNewLayoutIsSmall;
        }

        me.updateSlideWidth();
        me.visibleSlides = Math.floor((me.$content.outerWidth() + me.options.gap * 2) / me.slideWidth);

        var manualButtonOffset = me.$el.attr('data-manualButtonOffset');
        me.buttonOffset = manualButtonOffset
            ? parseInt(manualButtonOffset)
            : Math.floor((me.$content.outerWidth() - (me.visibleSlides * me.slideWidth)) / 2);

        me.scrollToOffset();
    }

    /**
     * @param {WheelEvent} e
     */
    onMouseWheel(e) {
        if (e.deltaX !== 0) {
            e.preventDefault();
        }

        if (this._lastMouseWheel && (Date.now() - this._lastMouseWheel < 1000)) {
            return;
        }

        var accept = false;
        var deltaXAbs = Math.abs(e.deltaX);

        switch (e.deltaMode) {
            case 0:
                // DOM_DELTA_PIXEL
                accept = (deltaXAbs > 50);
                break;
            case 1:
                // DOM_DELTA_LINE
                accept = (deltaXAbs > 5);
                break;
            case 2:
                // DOM_DELTA_PAGE
                accept = true;
                break;
        }

        if (accept) {
            this.handleClick(e.deltaX > 0);
            this.resetAutoPlayInterval();
            this._lastMouseWheel = Date.now();
        }
    }
}


export default USlider;
